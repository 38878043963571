import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import styles from "./success.module.scss"
import logo from "../../../images/Logo Page.png"
import instagram from "../../../images/winter2022/insta_rounded.svg"
import facebook from "../../../images/winter2022/facebook_rounded.svg"
import { IsMobileDevice } from "../../../utils"

const Success = ({ location }) => {
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    setIsMobile(IsMobileDevice())
  }, [])

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <img
          src={logo}
          className={styles.logo}
          onClick={() => navigate("/sunsets")}
        />
        {!isMobile ? (
          <>
            <h1> ¡GRACIAS! </h1>
            <h2>
            YA ESTÁS PARTICIPANDO <br /> ANUNCIAMOS LOS GANADORES EL 30/03/2023.
            </h2>
          </>
        ) : (
          <>
            <h1> ¡GRACIAS!  </h1>
            <h2>
            YA ESTÁS PARTICIPANDO <br /> ANUNCIAMOS LOS GANADORES EL 30/03/2023.
            </h2>
          </>
        )}
        <div className={styles.social}>
          <img src={facebook} className={styles.facebook} />
          <img src={instagram} className={styles.instagram} />
        </div>

        {isMobile ? (
          <h2 style={{ marginTop: "2vh" }}>
            MIENTRAS TANTO, <br /> NO TE PIERDAS NUESTROS <br /> SUNSET SESSIONS
            EN ARGENTINA. CONOCÉ MÁS ACÁ.
          </h2>
        ) : (
          <h2 style={{ marginTop: "2vh" }}>
            MIENTRAS TANTO, NO TE PIERDAS NUESTROS <br /> SUNSET SESSIONS EN
            ARGENTINA. CONOCÉ MÁS ACÁ.
          </h2>
        )}

        <button onClick={() => navigate("/sunsets/sessions")}>
          {" "}
          <h3> COMPRAR </h3>{" "}
        </button>
      </div>
    </div>
  )
}

export default Success
